<template>
    <ol class="list-group list-group-numbered">
        <li class="list-group-item d-flex align-items-start">
        This Quiz Has <span class="text-default ms-1 me-1"> {{ quiz.questions_count }} </span> questions
        </li>
        <li class="list-group-item d-flex align-items-start">
        Attempt <span class="text-default ms-1 me-1">all</span> questions
        </li>
        <li class="list-group-item d-flex align-items-start">
        Each Question carries <span class="text-default ms-1 me-1">1</span> mark
        </li>
        <li class="list-group-item d-flex align-items-start">
        <span class="text-default me-1">No </span> mark will be deducted by skipping a question
        </li>
        <li class="list-group-item d-flex align-items-start">
        You Have to Score Atleast <span class="text-default ms-1 me-1">{{ quiz.questions_count /2 }}</span> of <span class="text-default ms-1 me-1">{{ quiz.questions_count}}</span> marks to continue to next chapter
        </li>
        <li class="list-group-item d-flex align-items-start">
        If you score <span class="text-default ms-1 me-1">0</span> we will assume that you have not done the quiz
        </li>
        <li class="list-group-item d-flex align-items-start">
        Refreshing the browser will make a quiz invalid
        </li>
        <li class="list-group-item d-flex align-items-start">
        Time Alloted <span class="text-default ms-1 me-1"> {{ new Date(quiz.duration  * 1000).toISOString().substr(14, 5)}} </span> minutes
        </li>
    </ol>
</template>

<script>
export default {
    props:['quiz']

}
</script>

<style>

</style>