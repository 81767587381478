<template>
    <Navbar />
    <section class="courses-details-area pb-70" v-if="!isLoading">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="courses-details-desc text-start">
                <h2 class="text-center">{{ quiz.title }}</h2>
                <div v-if="quiz_results.score == 0">
                  <h4 class="text-center">Description</h4>
                  <span v-html="quiz.description"></span>
                  <h4 class="text-center">Instructions</h4>
                  <div class="text-center">
                    <Instructions :quiz="quiz" />
                  </div>
                  <div class="text-center">
                      <button class="default-btn" @click="takeQuiz(quiz.id)" >Start A Quiz</button>
                  </div>
                </div> 
                <div v-else>
                  <div class="text-center mt-5">
                    <h6>You have done the Quiz Already, You can only do the quiz once</h6>
                  </div>
                  <div class="text-center d-grid gap-2 d-md-block">
                      <router-link  class="default-btn btn" :to="{ name: 'CourseQuizResults',params: {id:  quiz.id}}">See Results</router-link>
                  </div>
                </div>         
            </div>
          </div>

        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios' 
import moment from 'moment'
import Instructions from '@/components/quiz/Instructions.vue'
export default {
 components: { Navbar, Loading, Instructions },
  data(){
    return {
      isLoading: true,
      showInstruction: false,
      subscriptionStatus: 0,
      rating: 4.3,
      user:{},
      quiz: {},
      question: {},
      quiz_results:{},
      token: localStorage.getItem('user_data'),
    }
  },
  methods:{
    takeQuiz(id){
      axios.post('https://apitraining.vipawaworks.com/api/quiz_question/get_quiz_fist_question/' + id).then(response => {
        this.question = response.data
        this.$router.push({ name: 'CourseQuizQuestion', params: {id: this.question.id} })
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getQuizResults(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/quiz/get_quiz_result/' + id).then(response => {
        this.quiz_results = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getQuiz(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/quiz/profile/' + id).then(response => {
        this.quiz = response.data
        document.title = this.quiz.title +' - Training'
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
    
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getQuiz() 
    this.getQuizResults() 
    this.moment = moment
  }
}
</script>

<style scope>
.list-group-item {
	position: relative;
	display: block;
	padding: .5rem 1rem;
	color: #212529;
	text-decoration: none;
	background-color: #fff;
	border: 0px solid rgba(0,0,0,.125);
}
</style>